.asset {
    height:200px;
    width:200px;
    opacity:0;
    transition: opacity 0.5s ease-in-out;
}

.asset:hover {
    opacity: 1;
}
