.timeline-item {
    background-color: #f3f3f3;
    color: #0c2d36;
    padding: 0 0 0 0px;
    margin: 0 0 0 0px;
    vertical-align: middle;
    horiz-align: left;
    border: 1px solid #CCCCCC;
    height: 25px;
    position: relative;
    display: block;
}

.timeline-item-content {
    height: 23px;
    left: 0px;
    width: 100%;
    background: linear-gradient(to bottom, rgb(130, 187, 197), rgb(82, 97, 115));
    display: flex;
    align-items: center;
    justify-content: end;
}

.timeline-item-drag-area {
    height: 23px;
    width: 10px;
    background: linear-gradient(to bottom, rgb(76, 149, 164), rgb(82, 97, 115));
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: col-resize;
    user-select: none;
}


.timeline-item:hover {
    background-color: #a1a1a1;
    color: #FFFFFF;
}
